.collapse {
  position: relative;
  width: 100%;
  border: none;
  background: transparent;
}

.header {
  font: 18px 'ProximaNova-Regular';
  letter-spacing: 1px;
  outline: none;
  border: none;
  display: flex;
  line-height: 140% !important;
  border-radius: 6px;
  flex-direction: row-reverse;
  justify-content: space-between;
  cursor: pointer;
}

.header:hover {
  background: rgba(35,191,184,0.2);
}

.expandIcon {
  font-size: 28px;
  margin-left: 10px;
}

.panel {
  padding: 20px 0;
}

.rc-collapse-content {
  background: rgba(255,255,255,0.8);
  padding: 0;
  margin-bottom: 0px;
}

.answer {
  font: 16px 'ProximaNova-Light';
  line-height: 140%;
  padding-left: 25px;
  margin-bottom: 0px;
}

.answer a {
  color: #23BFB8;
}
