@font-face {
  font-family: 'ProximaNova-Light';
  src: url('//cloudfront.startupmedia.com/greenback/fonts/ProximaNova-Light.otf') format('opentype');
}
@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('//cloudfront.startupmedia.com/greenback/fonts/ProximaNova-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('//cloudfront.startupmedia.com/greenback/fonts/ProximaNova-Regular.otf') format('opentype');
}

@font-face {
 font-family: 'Comfortaa-Regular';
 src: url('//cloudfront.startupmedia.com/greenback/fonts/Comfortaa_Regular.ttf') format('truetype');
}

body,html {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'ProximaNova-Light';
  color: #525868;
  /* background: #F0F0F0; */
  background: #FFF;
  height: 100%;
  /* min-width: 768px; */
}

/* .row { margin-bottom: 16px; } */
